// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../../src/components/seo'
import {Helmet} from 'react-helmet'
import {SchemaLinksToAbsolute} from '../../src/helpers/SchemaLinksToAbsolute'
import { useEffect } from 'react'
import CaseStudyHeroImageBlock from '../../src/components/page/CaseStudyHeroImageBlock'
import BasicTextBlock from '../../src/components/page/BasicTextBlock'
import CaseStudyParallaxImageText from '../../src/components/page/CaseStudyParallaxImageText'
import CaseStudyQuotation from '../../src/components/page/CaseStudyQuotation'
import CaseStudyTextStatBackgroundImage from '../../src/components/page/CaseStudyTextStatBackgroundImage'
import CaseStudyNumberedSlider from '../../src/components/page/CaseStudyNumberedSlider'
import CaseStudyAnimatedStatistics6BackgroundImage from '../../src/components/page/CaseStudyAnimatedStatistics6BackgroundImage'
import BasicTestimonial from '../../src/components/page/BasicTestimonial'
import BasicCallToAction from '../../src/components/page/BasicCallToAction'

const PageTemplate = pageProps => {
  let data = pageProps.data;
  const seoTitle = pageProps?.data?.wpService?.seo?.title || pageProps?.data?.wpCaseStudy?.seo?.title
  const seoDesc = pageProps?.data?.wpService?.seo?.metaDesc || pageProps?.data?.wpCaseStudy?.seo?.metaDesc
  const robotsNoFollow = pageProps?.data?.wpService?.seo?.metaRobotsNofollow || pageProps?.data?.wpCaseStudy?.seo?.metaRobotsNofollow
  const robotsNoIndex = pageProps?.data?.wpService?.seo?.metaRobotsNoindex || pageProps?.data?.wpCaseStudy?.seo?.metaRobotsNoindex
  const canonical = pageProps?.data?.wpService?.seo?.canonical || pageProps?.data?.wpCaseStudy?.seo?.canonical

  const returnPage = () => {
    if(pageProps.pageContext.template === 'CaseStudy'){
      return  {
        base: `/case-studies/`,
        baseName: 'Case Study',
        slug: pageProps.pageContext.slug,
        slugName: pageProps.pageContext.longTitle
      }
    }
    else if(pageProps.pageContext.template === 'Service'){
      return  {
        base: `/services/`,
        baseName: 'Services',
        slug: pageProps.pageContext.slug,
        slugName: pageProps.pageContext.longTitle
      }
    }
    else{
      return  {
        base: ``,
        baseName: '',
        slug: pageProps.pageContext.slug,
        slugName: pageProps.pageContext.longTitle
      }
    }
  }

data = pageProps.data.wpCaseStudy
  const componentsArray = data.acf_pageBuilder.pageBuilder || []
  const components = componentsArray.map(component => {
    return {
      name: component.__typename.split('_').pop(),
      data: component,
      pageInfo: returnPage(),
      showBreadcrumbs: true,
    }
  })
  let colourSetter = {
    color1: null, 
    color2: null, 
    headerColor: 'null',
  }

  if(pageProps.pageContext.slug === 'search-engine-optimisation'){
    colourSetter.color1 = '#EE6B4B';
    colourSetter.color2 = '#fd9c16';
    colourSetter.headerColor = 'black';
  }
  if(pageProps.pageContext.slug === 'conversion-rate-optimisation'){
    colourSetter.color1 = '#36E8F1';
    colourSetter.color2 = '#4F19FF';
    colourSetter.headerColor = 'black';
  }
  if(pageProps.pageContext.slug === 'pay-per-click-advertising'){
    colourSetter.color1 = '#CE58D1';
    colourSetter.color2 = '#ED8DF4';
    colourSetter.headerColor = 'black';
  }
  if(pageProps.pageContext.slug === 'copywriting'){
    colourSetter.color1 = '#FF3838';
    colourSetter.color2 = '#FF7A7A';
    colourSetter.headerColor = 'black';
  }
  if(pageProps.pageContext.slug === 'content-marketing'){
    colourSetter.color1 = '#299143';
    colourSetter.color2 = '#4DE745';
    colourSetter.headerColor = 'black';
  }
  if(pageProps.pageContext.slug !== 'content-marketing'
  && pageProps.pageContext.slug !== 'copywriting'
  && pageProps.pageContext.slug !== 'search-engine-optimisation'
  && pageProps.pageContext.slug !== 'conversion-rate-optimisation'
  && pageProps.pageContext.slug !== 'pay-per-click-advertising'
  && pageProps.pageContext.template === 'Service'
  ){
    colourSetter.color1 = pageProps.pageContext.color2;
    colourSetter.color2 = pageProps.pageContext.color1;
    colourSetter.headerColor = 'black';
  }
  const todaysDate = new Date();
  const year = todaysDate.getFullYear();

  const returnTemplate = () => {
    if(pageProps.pageContext.template === 'CaseStudy'){
      return  `/case-studies/${pageProps.pageContext.slug}/`
    }
    else if(pageProps.pageContext.template === 'Service'){
      return  `/services/${pageProps.pageContext.slug}/`
    }
    else{
      return `/${pageProps.pageContext.slug}/`
    }
  }
  

  const returnOGimage = () =>{
    if(pageProps.pageContext.customOgImage !== undefined){
      return process.env.GATSBY_SITE_URL+pageProps.pageContext.customOgImage.publicURL
    }else{
      return process.env.GATSBY_SITE_URL+pageProps?.pageContext?.featuredImage?.publicURL
    }
  }
  const returnOGwidth = () =>{
    if(pageProps.pageContext.customOgImage !== undefined){
      return pageProps.pageContext.customOgImage.childImageSharp?.original?.width
    }else{
      return 2216
      // return pageProps?.pageContext?.featuredImage?.childImageSharp?.original?.width
    }
  }
  const returnOGheight = () =>{
    if(pageProps.pageContext.customOgImage !== undefined){
      return pageProps.pageContext.customOgImage.childImageSharp?.original?.height
    }else{
      return 2216
      // return pageProps?.pageContext?.featuredImage?.childImageSharp?.original?.height
    }
  }

  useEffect(() => {
    if(typeof window !== 'undefined'){
      const scroller = document.querySelector('#container-scroller')
      const anchors = Array.from(document.querySelectorAll('[href^="#"]'))
      const header = document.querySelector('#header')
      const hh = header.offsetHeight
      
      if(anchors.length > 0){
        anchors.map(anchor => {
          anchor.addEventListener('click', function(e){
            e.preventDefault()
            console.log('clicked')
            const id = anchor.hash;
            const yourElement = document.querySelector(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset - hh - 35;
            scroller.scrollTo({top: y, behavior: 'smooth'});
          })
        })
      }
    }
  })

  return (
    <div 
    className='outer-component-wrapper' 
    data-color1={colourSetter.color1} 
    data-color2={colourSetter.color2} 
    data-headerColor={colourSetter.headerColor} 
    data-slug={pageProps.pageContext.slug}
    data-abbreviated-title={pageProps?.pageContext?.abbreviatedTitle}
    data-long-title={pageProps?.pageContext?.longTitle}
    data-case-title={pageProps?.pageContext?.caseStudyTitle}
    >
      
      <Seo 
      title={seoTitle} 
      description={seoDesc} 
      page={
        pageProps.pageContext.template === 'CaseStudy' ? "caseStudyPost" : ''
      }
      date={pageProps.pageContext.unformattedDate}
      slug={returnTemplate()}
      image={pageProps.pageContext.template === 'CaseStudy' ? returnOGimage() : ""}
      ogWidth={returnOGwidth()}
      ogHeight={returnOGheight()}
      robotsFollow={robotsNoFollow}
      robotsIndex={robotsNoIndex}
      canonicalUrl={canonical}
      />

      {pageProps.pageContext.schema !== undefined ? 
        <SchemaLinksToAbsolute schemaRaw={pageProps.pageContext.schema} /> 
      : "" }
      {/* {console.log(pageProps.pageContext)} */}
      {/* {
        pageProps.pageContext.template === 'Service'? 
        <Helmet>
          <script type="application/ld+json">
            {
              pageProps.pageContext.parentSlug !== undefined && pageProps.pageContext.parentTitle !== undefined ? 
              `{
                "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "${process.env.GATSBY_SITE_URL}"
                  },{
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Services",
                    "item": "${process.env.GATSBY_SITE_URL}/services/"
                  },{
                    "@type": "ListItem",
                    "position": 3,
                    "name": "${pageProps.pageContext.parentTitle}",
                    "item": "${process.env.GATSBY_SITE_URL}/services/${pageProps.pageContext.parentSlug}/"
                  },{
                  "@type": "ListItem",
                  "position": 4,
                  "name": "${pageProps.pageContext.longTitle}"
                }]
              }
              ` 
              : 
              `
              {
                "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "${process.env.GATSBY_SITE_URL}"
                  },{
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Services",
                    "item": "${process.env.GATSBY_SITE_URL}/services/"
                  },{
                    "@type": "ListItem",
                    "position": 3,
                    "name": "${pageProps.pageContext.longTitle}"
                  }]
              }
              ` 
            }
          </script>
        </Helmet>
        : ""
      } */}
        {
          pageProps.pageContext.template === 'CaseStudy' ?  
            
            <Helmet>
            <script type="application/ld+json">
              {
                `{
                  "@context": "https://schema.org/",
                  "@type": "Article",
                  "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "${process.env.GATSBY_SITE_URL}/insights/${pageProps.pageContext.slug}/",
                      "name": "${pageProps.pageContext.title}"
                  },
                  "copyrightYear": "${year}",
                  "datePublished": "${pageProps.pageContext.formattedDate}",
                  "dateModified": "${pageProps.pageContext.formattedModified ? pageProps.pageContext.formattedModified : pageProps.pageContext.formattedDate}",
                  "headline": "${pageProps.pageContext.companyName} Case Study",
                  "description": "${seoDesc}",
                  "inLanguage": "en-gb",
                  "image": {
                    "@type": "ImageObject",
                    "url": "${returnOGimage()}",
                    "width": "${returnOGwidth()}",
                    "height": "${returnOGheight()}"
                  },
                  "author": {
                    "@type": "Person",
                    "name": "${pageProps.pageContext.author.node.name}"
                  },
                  "publisher": {
                    "@type": "Organization",
                    "name": "Edge45®",
                    "logo": {
                      "@type": "ImageObject",
                      "url": "${process.env.GATSBY_SITE_URL+pageProps.pageContext.organisationImage.publicURL}",
                      "width": "${pageProps?.pageContext?.organisationImage?.childImageSharp?.original?.width}",
                      "height": "${pageProps?.pageContext?.organisationImage?.childImageSharp?.original?.height}"
                    }
                  }
                
              }`
              }
            </script>
            </Helmet>
          : ""
        }
    {/* <div className={pageProps.pageContext.slug}> */}
      {components.map((component, index) => {

      if (component.name == 'CaseStudyHeroImageBlock') {
        return <CaseStudyHeroImageBlock {...component.data} key={index} />
      }
    

      if (component.name == 'BasicTextBlock') {
        return <BasicTextBlock {...component.data} key={index} />
      }
    

      if (component.name == 'CaseStudyParallaxImageText') {
        return <CaseStudyParallaxImageText {...component.data} key={index} />
      }
    

      if (component.name == 'CaseStudyQuotation') {
        return <CaseStudyQuotation {...component.data} key={index} />
      }
    

      if (component.name == 'CaseStudyTextStatBackgroundImage') {
        return <CaseStudyTextStatBackgroundImage {...component.data} key={index} />
      }
    

      if (component.name == 'CaseStudyNumberedSlider') {
        return <CaseStudyNumberedSlider {...component.data} key={index} />
      }
    

      if (component.name == 'CaseStudyAnimatedStatistics6BackgroundImage') {
        return <CaseStudyAnimatedStatistics6BackgroundImage {...component.data} key={index} />
      }
    

      if (component.name == 'BasicTestimonial') {
        return <BasicTestimonial {...component.data} key={index} />
      }
    

      if (component.name == 'BasicCallToAction') {
        return <BasicCallToAction {...component.data} key={index} />
      }
    
        return <div>Error: The component {component.name} was not found</div>
      })}
    </div>
  )
}

export default PageTemplate


    export const query = graphql`
      query PageQuery508($id: String!) {
        wpCaseStudy(id: {eq: $id}) {
          title
          seo{
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
            canonical
          }
          
    acf_pageBuilder {
      pageBuilder {
        __typename
         
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicAccordion {
         
        accordion {
            heading
            text
        }
        sectionTitle {
            headingType
            titleText
        }
        removeBottomMargin
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicBigBoldStatementBlockQuote {
        
   
            fieldGroupName
            removeBottomMargin
            statement
            textColour
   
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicBrandLogos {
        
      
        label
        removeBottomMargin
        removeGrayscale
        brandLogosGatsby {
          logo {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicCallToAction {
        
      
        fieldGroupName
        removeBottomMargin
        callToActionGatsby {
          ctaButtons {
            buttonText
            pageLink {
              ... on WpPage {
                id
                link
              }
            }
          }
          ctaHeading
        }
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicFullWidthImage {
        
 
        caption
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        notFullWidth
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicFullWidthVideo {
        
      
        removeBottomMargin
        videoEmbedCode
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicGallery {
        
      
        fieldGroupName
        removeBottomMargin
        gallery {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
              relativePath
            }
          }
        }
       
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicImageGrid {
        
 
       imageGrid{
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
       }
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicMoreServices {
        
      
        removeBottomMargin
        pickServicesGatsby {
          service1 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service2 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service3 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service4 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service5 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicRelatedPosts {
        
      
        removeBottomMargin
        postPicker {
          buttonText
          headline
          label
          rolloverText
          pickPost {
            ... on WpBlog {
              id
              link
              title
              acf_customFeaturedImage {
                customFeaturedImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
            ... on WpCaseStudy {
              id
              link
              title
              acf_caseStudyFields {
                backgroundImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
          }
          rolloverText
        }
        text
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicTable {
        
      
        fieldGroupName
        removeBottomMargin
        tableRow {
          isHeaderRow
          highlightRow
          tableColumns {
            tableCell
          }
        }
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicTestimonial {
        
      
        fieldGroupName
        personPositionCompany
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        personPhotoOffsets {
          offsetX
          offsetY
        }
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicTestimonialSlider {
        
        fieldGroupName
        testimonialSlider{
          personPositionCompany
          personPhoto {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          personPhotoOffsets {
            offsetX
            offsetY
          }
          quotation
        }
        removeBottomMargin
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicTextBlock {
        
      
        addDividerLine
        removeBottomMargin
        text

    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_BasicTextBlock2col {
        
      
        addDividerLine
        removeBottomMargin
        text
        text2
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatisticArrow {
        
      
      removeBottomMargin
      animatedStatisticWArrow {
        fieldGroupName
        label
        number
        symbol
      }
    
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatistics6BackgroundImage {
        
        
          fieldGroupName
          heading
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          removeBottomMargin
          text
          statistics {
            addColourBackground
            fieldGroupName
            label
            number
            symbol
          }
        
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_CaseStudyHeroImageBlock {
        
      
        backgroundImageFocalPoint
        backgroundImageOpacity
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        heading
        logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        logoWidth
        removeBottomMargin
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_CaseStudyNumberedSlider {
        
      
        removeBottomMargin
        slider {
          imageType
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          text
        }
        sliderLabel
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_CaseStudyParallaxImageText {
        
      
        backgroundColour1
        backgroundColour2
        removeBottomMargin
        text
        parallaxImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_CaseStudyQuotation {
        
      
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_CaseStudyQuotationSlider {
        
      quotations {
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
      }
      removeBottomMargin
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_CaseStudyTextStatBackgroundImage {
        
      
        removeBottomMargin
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        text
        statisticGatsby {
          label
          number
          symbol
        }
    
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_ServiceBarChartTextButton {
        
      
        fieldGroupName
        removeBottomMargin
        barChart {
          chartTitle
          dataValues {
            dataValue
            xDataValue
          }
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
        }
        barChart2datasets {
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
        }
        singleOrMultipleDatasets
        text
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              id
              link
            }
          }
          buttonText
        }
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_ServiceCallToActionWImage {
        
      
        backgroundColor1
        backgroundColor2
        heading
        headingTag
        removeBottomMargin
        text
        buttonGatsby {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
              title
            }
          }
          buttonText
        }
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_ServiceIntroSection {
        
        addCountdownTimer
        countdownFrom
        heading
        removeBottomMargin
        scrollingBackgroundText
        agencyPrefixText
        source
        text
        ctaButton{
            target
              title
              url
        }
        animatedIconVideoUrl
        specifyParentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
        specifyGrandparentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_ServiceLineChartTextButton {
        
      
        removeBottomMargin
        lineChart {
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xLabelCustomTooltip
          xUnitLabel
          xUnitLabelCustomTooltip
          yLabelCustomTooltip
          yLabel
          yUnitLabel
          yUnitLabelCustomTooltip
        }
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
            }
          }
          buttonText
        }
        text
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_ServiceTextImage {
        
        useCustomBackgroundColour
        backgroundColour1
        backgroundColour2
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        imageFocalPoint
        imageToFillSpace
        label
        padding {
          paddingBottom
          paddingLeft
          paddingRight
          paddingTop
        }
        removeBottomMargin
        text
        textimagebutton{
          textimagebuttontext
          textimagebuttonlink
        }
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_ServiceTextVideo {
        
      
        backgroundColour1
        backgroundColour2
        imageToFillSpace
        video
        removeBottomMargin
        text
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_Services3Image {
        
      
        removeBottomMargin
        imagesX3 {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_ServicesServicesGridVideoTitleText {
        
      
        removeBottomMargin
        servicesServicesGridVideoTitleText {
          videoUrl
          text
          title
          linkToService {
            ... on WpService {
              uri
            }
          }
        }
        servicesGridHeading
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_ServicesStaffBlock {
        
      
        servicesStaffBlockHeading
        servicesStaffBlockIntroText
        servicesStaffRepeater{
            text
            staffMember{
                ... on WpTeamMember{
                    title
                    slug
                    acf_staff_member{
                        colour
                        image{
                            altText
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1920) {
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      
    
      }
     
 
      ... on WpCaseStudy_AcfPagebuilder_PageBuilder_VideoTranscriptionBlock {
        


        text

    
      }
    
      }
    }
  
        }
      }
    `
  